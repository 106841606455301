<template>
  <div />
</template>

<script>
import { Vue, Component } from 'vue-property-decorator'

@Component({
  head: {
    title() {
      return {
        inner: 'Dashboard',
      }
    },
  },
})
export default class Dashboard extends Vue {}
</script>
